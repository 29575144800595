import { useEffect, useState } from "react";

export const useFetch = (url, options) => {
  const [state, setState] = useState({
    loading: false,
    response: null,
    error: null,
  });

  useEffect(() => {
    setState((state) => Object.assign({}, state, { loading: true }));
    // see https://dev.to/pallymore/clean-up-async-requests-in-useeffect-hooks-90h
    const abortController = new AbortController();
    fetch(
      url,
      Object.assign({}, options || {}, { signal: abortController.signal }),
    )
      .then((response) => response.json())
      .then((response) => {
        setState((state) =>
          Object.assign({}, state, { response: response, error: null }),
        );
      })
      .catch((error) => {
        if (!abortController.signal.aborted) {
          setState((state) => Object.assign({}, state, { error: error }));
        }
      })
      .finally(() =>
        setState((state) => Object.assign({}, state, { loading: false })),
      );

    return () => abortController.abort();
  }, [url, options]);

  return state;
};
