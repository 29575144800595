import React from "react";
import { Chart } from "./Chart";
import { Leaderboard } from "./Leaderboard";

const teams = [
  "Softwareberader",
  "Twin Radformation",
  "8000 Watt Albradtadler Großblattfahrer",
];

export const App = () => (
  <div className="container-fluid">
    <div className="row">
      <div className="col">
        <h1 className="display-3 py-3">MdRzA Leaderboard</h1>
      </div>
    </div>

    <div className="row">
      <div className="col col-lg-8 col-xl-6">
        <Leaderboard teams={teams} />
      </div>
      <div className="col col-lg-4 col-xl-6">
        <Chart teams={teams} />
      </div>
    </div>

    <div className="row">
      <div className="col small text-right">
        &copy; 2019-2024 by David Fuhr,{" "}
        <a href="https://www.esentri.com" target="_blank" rel="noreferrer">
          esentri AG
        </a>
      </div>
    </div>
  </div>
);
