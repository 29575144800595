import { IntegratedSorting, SortingState } from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
} from "@devexpress/dx-react-grid-bootstrap4";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Spinner } from "./Spinner";
import { useFetch } from "./use-fetch";

export const Leaderboard = ({ teams }) => {
  const [columns] = useState([
    {
      name: "team_name",
      title: "Team",
      // width: '30%',
    },
    {
      name: "km_per_member",
      // dataType: 'number',
      title: "km/Person",
    },
    {
      name: "km_sum",
      // dataType: 'number',
      title: "km",
    },
    {
      name: "member_count",
      // dataType: 'number',
      title: "Personen",
    },
    {
      name: "day_count",
      // dataType: 'number',
      title: "Tage",
    },
  ]);

  const teamsParam = (teams || [])
    .map((team) => "teams=" + encodeURIComponent(team))
    .join("&");
  const { loading, response } = useFetch(
    `${process.env.REACT_APP_BACKEND_BASE_URI}leaderboard.json?${teamsParam}`,
  );
  const rows = response || [];

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <Grid columns={columns} rows={rows}>
          <SortingState
            defaultSorting={[
              {
                columnName: "km_per_member",
                direction: "desc",
              },
            ]}
          />
          <IntegratedSorting />
          <Table />
          <TableHeaderRow showSortingControls />
        </Grid>
      )}
    </>
  );
};

Leaderboard.propTypes = {
  teams: PropTypes.arrayOf(PropTypes.string).isRequired,
};
