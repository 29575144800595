import { ArgumentScale, EventTracker } from "@devexpress/dx-react-chart";
import {
  ArgumentAxis,
  Chart as DxChart,
  Legend,
  LineSeries,
  Tooltip,
  ValueAxis,
} from "@devexpress/dx-react-chart-bootstrap4";
import { scaleTime } from "d3-scale";
import { curveStepAfter, line } from "d3-shape";
import PropTypes from "prop-types";
import React from "react";
import { Spinner } from "./Spinner";
import { useFetch } from "./use-fetch";

const Line = (props) => (
  <LineSeries.Path
    {...props}
    path={line()
      .x(({ arg }) => arg)
      .y(({ val }) => val)
      .curve(curveStepAfter)}
  />
);

const startDate = new Date(new Date().getFullYear() + "-07-26");

const reduceToTeamNames = (data) =>
  Array.from(
    data.reduce((accumulator, currentValue) => {
      Object.keys(currentValue)
        .filter((key) => key !== "fetch_date")
        .forEach((teamName) => accumulator.add(teamName));
      return accumulator;
    }, new Set()),
  );

const findByFetchDate = (data, date) =>
  data.find((element) => element.fetch_date.getTime() === date.getTime());

export const Chart = ({ teams }) => {
  const teamsParam = (teams || [])
    .map((team) => "teams=" + encodeURIComponent(team))
    .join("&");
  const { loading, response } = useFetch(
    `${process.env.REACT_APP_BACKEND_BASE_URI}chart.json?${teamsParam}`,
  );
  const transposedData = (response || []).reduce(
    (accumulator, currentValue) => {
      const currentFetchDate = new Date(currentValue.fetch_date);
      if (currentFetchDate < startDate) {
        return accumulator;
      }

      let node = findByFetchDate(accumulator, currentFetchDate);
      if (!node) {
        node = { fetch_date: currentFetchDate };
        accumulator.push(node);
      }
      node[currentValue.team_name] = currentValue.km_per_member;
      return accumulator;
    },
    [],
  );
  const interpolatedData = ((data) => {
    // create start node with 0 for all teams
    let startNode = findByFetchDate(data, startDate);
    if (!startNode) {
      startNode = { fetch_date: startDate };
      data.push(startNode);
    }
    for (const teamName of reduceToTeamNames(data)) {
      if (!startNode[teamName]) {
        startNode[teamName] = 0;
      }
    }
    return data;
  })(transposedData);
  const data = interpolatedData.sort((a, b) => a.fetch_date > b.fetch_date);

  const series = reduceToTeamNames(data).map((currentValue) => (
    <LineSeries
      name={currentValue}
      key={currentValue}
      argumentField="fetch_date"
      valueField={currentValue}
      seriesComponent={Line}
    />
  ));

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <DxChart data={data}>
          <ArgumentAxis />
          <ArgumentScale factory={scaleTime} />
          <ValueAxis />
          <Legend position="bottom" />
          {series}
          <EventTracker />
          <Tooltip />
        </DxChart>
      )}
    </>
  );
};

Chart.propTypes = {
  teams: PropTypes.arrayOf(PropTypes.string).isRequired,
};
